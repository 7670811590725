import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import Person4Icon from "@mui/icons-material/Person4";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import HouseIcon from "@mui/icons-material/House";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import StyleIcon from "@mui/icons-material/Style";
import image2 from "../../../../assets/sport.png";

function Acheivements() {
  return (
    <Grid
      container
      id="acheivements"
      sx={{ backgroundColor: "#1d67ab", color: "#fff", padding: "30px", mt: 5 }}
    >
      <Grid item xs={12} md={6}>
        <Typography
          sx={{ fontSize: { xs: "25px", md: "30px" }, fontWeight: "bold" }}
        >
          Some facts of our school
        </Typography>
        <Typography sx={{ mb: 4, textAlign: { xs: "center", md: "left" } }}>
          Discover the Wonders - Unveiling Our School's Marvels.
        </Typography>
        <Box
          sx={{
            backgroundImage: `url(${image2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: { xs: "250px", md: "400px" },
            maxWidth: "550px",
            borderRadius: "10px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} container spacing={3} justifyContent="center">
        {[
          { icon: <GroupsIcon />, value: "1200", label: "Students" },
          { icon: <Person4Icon />, value: "12", label: "Teachers" },
          { icon: <PersonAddAlt1Icon />, value: "700", label: "Average A+" },
          { icon: <HouseIcon />, value: "50", label: "Rooms" },
          { icon: <MilitaryTechIcon />, value: "112", label: "Awards" },
          { icon: <StyleIcon />, value: "56", label: "Courses" },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                width: "100%",
                mt: { xs: 5, md: 0 },
                height: "230px",
                boxShadow: 3,
                border: "1px solid #fff",
                backgroundColor: "#fff",
                borderRadius: 5,
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  boxShadow: 6,
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "black",
                }}
              >
                {React.cloneElement(item.icon, {
                  sx: { fontSize: 50, color: "#fdc800" },
                })}
                <Typography
                  sx={{ fontSize: "35px", fontWeight: "bold", mt: 2 }}
                >
                  {item.value}
                </Typography>
                <Divider
                  sx={{
                    my: 1,
                    width: "100%",
                    backgroundColor: "#fdc800",
                    mt: 2,
                  }}
                />
                <Typography sx={{ fontSize: "20px", mt: 2 }}>
                  {item.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default Acheivements;
