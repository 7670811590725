import React, { useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import c1 from "../../../../assets/c1.png";
import c2 from "../../../../assets/c2.jpg";
import c3 from "../../../../assets/c3.png";
import c4 from "../../../../assets/c4.jpg";
import c5 from "../../../../assets/c5.png";

const Courses = () => {
  const scrollRef = useRef(null);
  const cardWidth = 320; // Adjust based on card width

  const data = [
    {
      image: c1,
      title: "Play Ground",
      context:
        "A fun-filled space where little explorers discover, play, and grow with joy through music, storytelling, and hands-on activities.",
    },
    {
      image: c2,
      title: "Nursery",
      context:
        "A perfect blend of play and learning, building confidence, curiosity, and essential skills through colors, numbers, and creative exploration.",
    },
    {
      image: c3,
      title: "Junior KG",
      context:
        "Bridging play and structured learning with interactive lessons, early literacy, numeracy, and exciting hands-on activities.",
    },
    {
      image: c4,
      title: "Senior KG",
      context:
        "Preparing young minds for primary school with engaging storytelling, math concepts, problem-solving, and creative thinking.",
    },
    {
      image: c5,
      title: "DayCare",
      context:
        "A safe, nurturing space where care, comfort, and fun come together, ensuring holistic growth, social bonding, and happy learning.",
    },
  ];

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: direction === "left" ? -cardWidth - 20 : cardWidth + 20,
        behavior: "smooth",
      });
    }
  };

  return (
    <Grid
     id="courses"
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: { xs: "20px", md: "50px" },
        textAlign: "center",
        position: "relative",
        margin: "auto",
      }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "24px", md: "30px" },
            marginBottom: "10px",
          }}
        >
          OUR MAIN COURSES
        </Typography>
        <Typography
          sx={{ marginBottom: "20px", fontSize: { xs: "14px", md: "16px" } }}
        >
          Fusce sem dolor, interdum in efficitur at
        </Typography>
      </Grid>

      {/* Left Arrow */}
      <IconButton
        sx={{
          position: "absolute",
          left: 0,
          top: "50%",
          transform: "translateY(-50%)",

          zIndex: 10,
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
        onClick={() => scroll("left")}
      >
        <ArrowBackIos />
      </IconButton>

      {/* Scrollable Card Section */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          overflowX: "auto",
          gap: { xs: 2, md: 3 },
          padding: "20px",
          scrollBehavior: "smooth",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": { display: "none" },
          minWidth: "100%",
        }}
        ref={scrollRef}
      >
        {data.map((item, index) => (
          <Card
            sx={{
              width: `${cardWidth}px`,
              minWidth: `${cardWidth}px`,
              flexShrink: 0,
              height: { xs: "400px", md: "450px" },
              position: "relative",
              boxShadow: 3,
            }}
            key={index}
          >
            <CardContent sx={{ padding: 0 }}>
              <Box sx={{ position: "relative", overflow: "hidden" }}>
                <img
                  src={item.image}
                  alt={item.title}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </Box>

              <Box sx={{ padding: "10px", backgroundColor: "white" }}>
                <Typography
                  sx={{
                    fontSize: { xs: 20, md: 25 },
                    fontWeight: "bold",
                    marginTop: "10px",
                    textAlign: "left",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "10px",
                    textAlign: "justify",
                    fontSize: { xs: "12px", md: "14px" },
                  }}
                >
                  {item.context}
                </Typography>
                <Button
                  sx={{
                    backgroundColor: "#1d67ab",
                    color: "#fff",
                    mt: 4,
                    fontSize: { xs: "12px", md: "14px" },
                    padding: { xs: "5px 10px", md: "8px 16px" },
                  }}
                >
                  APPLY NOW
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Grid>

      {/* Right Arrow */}
      <IconButton
        sx={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",

          zIndex: 10,
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
        onClick={() => scroll("right")}
      >
        <ArrowForwardIos />
      </IconButton>
    </Grid>
  );
};

export default Courses;
