import React from "react";
import Slider from "react-slick";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import banner1 from "../../../../assets/banner1.jpg";
import banner2 from "../../../../assets/banner2.png";
import banner3 from "../../../../assets/banner3.png";

const BannerPage = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: (
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          zIndex: 1,
          color: "Blue",
          transform: "translateY(-50%)",
        }}
      ></IconButton>
    ),
    nextArrow: (
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          right: "20px",
          zIndex: 1,
          color: "white",
          transform: "translateY(-50%)",
          fontSize: "30px",
        }}
      ></IconButton>
    ),
  };

  return (
    <Box id="home" sx={{ width: "100%", position: "relative", mt: 1 }}>
      <Slider {...settings}>
        <Box
          sx={{
            backgroundImage: `url(${banner3})`,
            backgroundSize: "cover", // Ensures the image covers the entire area
            backgroundPosition: "center",
            height: { xs: "300px", md: "600px" }, // Height adjusts based on screen size
            width: "100%",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>

        <Box
          sx={{
            backgroundImage: `url(${banner2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: { xs: "300px", md: "600px" },
            width: "100%",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>

        <Box
          sx={{
            backgroundImage: `url(${banner1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: { xs: "300px", md: "600px" },
            width: "100%",
            backgroundRepeat: "no-repeat",
          }}
        ></Box>
      </Slider>
    </Box>
  );
};

export default BannerPage;
