import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import NewsHeader from "../componets/NewsHeader";
import Header from "../componets/Header";
import Footer from "../componets/Footer";
import image2 from "../../../../assets/image2.jpg";
import image from "../../../../assets/image.png";
import banner from "../../../../assets/banner.jpg";
import image1 from "../../../../assets/image1.jpg";
import image4 from "../../../../assets/image4.jpg";
import image6 from "../../../../assets/image6.jpg";

const cardData = [
  { title: "Sports Events", description: "Sports Events", image: image },
  { title: "Cultural Fest", description: "Cultural Fest", image: banner },
  {
    title: "Science Exhibition",
    description: "Science Exhibition",
    image: image1,
  },
  { title: "Art Gallery", description: "Art Gallery", image: image2 },
  { title: "Activities", description: "Activities", image: image6 },
  { title: "Drama Fest", description: "Drama Fest", image: image4 },
];

const Gallary = () => {
  return (
    <>
      {/* <NewsHeader />
      <Header /> */}
      <Grid
        container
        id="gallary"
        sx={{ padding: "50px", flexDirection: "column" }}
      >
        <Box mb={4}>
          <Typography sx={{ fontWeight: "bold", fontSize: "30px" }}>
            Gallery
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={4} md={4} key={index}>
              <Card
                sx={{ height: "auto", width: "100%", position: "relative" }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <img
                    src={card.image}
                    alt={card.title}
                    style={{
                      width: "100%",
                      height: { xs: "250px", mmd: "300px" },
                      objectFit: "cover",
                      borderRadius: "8px 8px 0 0",
                    }}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      backgroundColor: "#fdc800",
                      padding: "8px",
                      borderRadius: "0 0 8px 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "black",
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography sx={{ color: "black" }}>
                      {card.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </>
  );
};

export default Gallary;
