import { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as ScrollLink } from "react-scroll";

import logotext from "../../../../assets/logo.png";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: "Home", to: "home" },
    { text: "About Us", to: "about" },
    { text: "Courses", to: "courses" },
    { text: "Pillars", to: "Pillars" },
    { text: "Achievements", to: "acheivements" },
    { text: "Gallery", to: "gallary" },
  ];

  return (
    <Grid
      container
      sx={{
        padding: "5px 20px",
        alignItems: "center",
        justifyContent: "space-between",
        position: "sticky",
        top: 0,
        zIndex: 1,
        backgroundColor: "#fff",
        boxShadow: 5,
        "@media (max-width:600px)": { padding: "1px 10px" },
      }}
    >
      {/* Logo and Title */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          ml: { xs: 0, sm: 2 }, // Adjust margin-left based on screen size
        }}
      >
        <img
          src={logotext}
          alt="GMC Logo"
          style={{
            width: isMobile ? "200px" : "300px", // 200px on mobile, 300px on larger screens
            height: "auto", // Maintain aspect ratio
          }}
        />
      </Box>

      {/* Desktop Menu (Hidden on Mobile) */}
      <Box
        sx={{
          gap: 2,
          display: "flex",
          "@media (max-width:600px)": { display: "none" },
        }}
      >
        {menuItems.map((item, index) => (
          <ScrollLink key={index} to={item.to} smooth duration={500}>
            <Button
              sx={{
                color: "black",
                backgroundColor: "#fff",
                fontWeight: "bold",
                ":hover": {
                  color: "#1d67ab",
                  backgroundColor: "#fff",
                  fontWeight: "bold",
                },
              }}
            >
              {item.text}
            </Button>
          </ScrollLink>
        ))}
        <Box>
          <Button
            component={ScrollLink}
            to="contact" // Corrected spelling from "gallary" to "gallery"
            smooth
            duration={500}
            sx={{
              backgroundColor: "#1d67ab",
              color: "#fff",
              borderRadius: 10,
              fontWeight: "bold",
              animation: "blinkColor 1s infinite",
              px: 2,
              textTransform: "none",
            }}
          >
            Admission
          </Button>
          <style>
            {`
      @keyframes blinkColor {
        0% { color: #fff ,backgroundColor:red}
        50% { color: yellow; }
        100% { color: #fff; }
      }
    `}
          </style>
        </Box>
      </Box>

      {/* Mobile Menu - Drawer (Left Side) */}
      <Box sx={{ display: { xs: "flex", sm: "none" } }}>
        <IconButton onClick={toggleDrawer(true)} sx={{ color: "black" }}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 250, mt: 5 }}>
            <List>
              {menuItems.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={toggleDrawer(false)}>
                    <ScrollLink
                      to={item.to}
                      smooth
                      duration={500}
                      style={{
                        textDecoration: "none",
                        color: "black",
                        width: "100%",
                      }}
                    >
                      <ListItemText primary={item.text} />
                    </ScrollLink>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </Grid>
  );
};

export default Header;
