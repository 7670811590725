// import {
//   Button,
//   Divider,
//   Grid,
//   IconButton,
//   Modal,
//   TextField,
//   Typography,
//   useMediaQuery,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope, faXmark } from "@fortawesome/free-solid-svg-icons";
// import admission from "../../../../assets/banner1.jpg";
// import axios from "axios";

// const Popup = ({ open, onClose, isApplyButton }) => {
//   const mobileScreen = useMediaQuery(
//     "(min-width: 360px) and (max-width: 599px)"
//   );
//   const laptopScreen = useMediaQuery(
//     "(min-width: 1200px) and (max-width: 1440px)"
//   );
//   const [name, setName] = useState("");
//   const [phone, setPhone] = useState("");
//   const [message, setMessage] = useState("");

//   const handlePhoneChange = (e) => {
//     const { value } = e.target;
//     const numericValue = value.replace(/\D/g, "");
//     if (numericValue.length <= 10) {
//       setPhone(numericValue);
//     }
//   };

//   const handleApply = async (event) => {
//     event.preventDefault();
//     try {
//       const ipInfoResponse = await fetch("https://ipinfo.io/json");
//       const ipInfo = await ipInfoResponse.json();

//       const data = {
//         fullName: name,
//         phoneNumber: phone,
//         ip: ipInfo.ip,
//         city: ipInfo.city,
//         region: ipInfo.region,
//         country: ipInfo.country,
//         loc: ipInfo.loc,
//       };

//       const response = await fetch(
//         "https://api.podarprepyelahanka.com/addLead",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(data),
//         }
//       );

//       if (response.ok) {
//         setName("");
//         setPhone("");
//         setMessage("");
//         sessionStorage.setItem("isFormSubmited", true);
//         onClose(); // Close the popup after successful submission
//       } else {
//         console.error("Failed to submit the form.");
//       }
//     } catch (error) {
//       console.error("Error submitting form:", error);
//     }
//   };
//   const handleSubmit = async () => {
//     const requestData = {
//       name: name, // Ensure 'name' is defined
//       phone: phone, // Ensure 'phone' is defined
//       leadType: "PodarPrepYelahanka",
//       leadFlag: "Paid",
//     };

//     try {
//       const response = await axios.post(
//         "https://gmc.getmycollege.com/university/addExternalSourceLead",
//         requestData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response?.status >= 200 && response?.status < 300) {
//         setName("");
//         setPhone("");
//         setMessage("");
//         sessionStorage.setItem("isFormSubmited", true);
//         onClose(); // Close the popup after successful submission
//         alert("Thank You!");
//       } else {
//         alert("Failed to add lead");
//       }
//     } catch (error) {
//       alert("Something went wrong. Please try again later.");
//       console.error(error);
//     }
//   };

//   return (
//     <Modal open={open} onClose={isApplyButton && onClose}>
//       <Grid
//         sx={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           background: "#ffffff",
//           // padding: 4,
//           borderRadius: 1,
//         }}
//       >
//         {/* <IconButton
//           sx={{
//             position: "absolute",
//             top: 1,
//             right: mobileScreen ? 2 : 5,
//             color: "#2E65AD",
//             width: mobileScreen ? 40 : "auto",
//             height: mobileScreen ? 40 : "auto",
//             backgroundColor: mobileScreen
//               ? "rgba(255,255,255,0.8)"
//               : "transparent",
//             "&:hover": {
//               backgroundColor: mobileScreen
//                 ? "rgba(255,255,255,0.8)"
//                 : "transparent",
//             },
//           }}
//           onClick={onClose}
//         >
//           <FontAwesomeIcon icon={faXmark} />
//         </IconButton> */}
//         <Grid container md={12} xs={12}>
//           <Grid
//             item
//             md={6}
//             xs={12}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: mobileScreen ? "center" : "flex-start",
//             }}
//           >
//             <Grid
//               sx={{
//                 width: mobileScreen ? 330 : laptopScreen ? 870 : 800,
//                 height: mobileScreen ? 240 : laptopScreen ? 450 : 560,
//               }}
//             >
//               <img
//                 src={admission}
//                 alt="admissionbanner"
//                 style={{
//                   width: "100%",
//                   height: "100%",
//                   objectFit: mobileScreen
//                     ? "cover"
//                     : laptopScreen
//                     ? "fill"
//                     : "fill",
//                 }}
//               />
//             </Grid>
//           </Grid>

//           <Grid
//             item
//             md={6}
//             xs={12}
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "flex-end",

//               px: laptopScreen ? 2 : 4,
//               py: mobileScreen ? 2 : laptopScreen ? 4 : 0,
//             }}
//           >
//             <Grid
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 color: "#000",
//               }}
//             >
//               <Typography
//                 variant="h3"
//                 sx={{
//                   fontSize: mobileScreen ? 20 : laptopScreen ? 25 : 28,
//                   fontWeight: 600,
//                 }}
//               >
//                 Quickly&nbsp;
//                 <span style={{ color: "#2E65AD" }}>admit</span>
//                 &nbsp;here
//               </Typography>

//               <Grid
//                 sx={{
//                   display: mobileScreen ? "none" : "block",
//                   py: mobileScreen ? 2 : laptopScreen ? 2 : 3,
//                 }}
//               >
//                 <Divider
//                   sx={{
//                     background: "#2E65AD",
//                     width: 150,
//                     textAlign: "center",
//                   }}
//                 />
//               </Grid>
//               <Typography
//                 sx={{
//                   display: mobileScreen ? "none" : "block",
//                   fontSize: mobileScreen ? 16 : laptopScreen ? 15 : 17,
//                   textAlign: "center",
//                   px: mobileScreen ? 3 : 0,
//                   lineHeight: "20px",
//                 }}
//               >
//                 Join the Podar Prep Family - Where Learning Feels Like Fun!
//               </Typography>

//               <Grid
//                 sx={{
//                   display: mobileScreen ? "none" : "block",
//                   py: mobileScreen ? 2 : laptopScreen ? 2 : 3,
//                 }}
//               >
//                 <Divider
//                   sx={{
//                     background: "#2E65AD",
//                     width: 150,
//                     textAlign: "center",
//                   }}
//                 />
//               </Grid>
//             </Grid>
//             <Grid
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 py: mobileScreen ? 2 : laptopScreen ? 3 : 4,
//               }}
//             >

//                 <Grid container spacing={mobileScreen ? 1.5 : 3}>
//                   <Grid item md={12} xs={12}>
//                     <TextField
//                       fullWidth
//                       placeholder="Enter Your Name"
//                       type="text"
//                       required
//                       value={name}
//                       onChange={(e) => setName(e.target.value)}
//                       inputProps={{
//                         style: {
//                           height: mobileScreen ? 7 : laptopScreen ? 9 : 10,
//                           fontSize: mobileScreen ? 12 : laptopScreen ? 10 : 13,
//                           background: "#fff",
//                           borderRadius: 0,
//                         },
//                       }}
//                       variant="outlined"
//                       sx={{
//                         margin: 0,
//                       }}
//                     />
//                   </Grid>
//                   <Grid item md={12} xs={12}>
//                     <TextField
//                       fullWidth
//                       placeholder="Enter Your Ph.No"
//                       type="tel"
//                       required
//                       value={phone}
//                       onChange={handlePhoneChange}
//                       inputProps={{
//                         maxLength: 10,
//                         style: {
//                           height: mobileScreen ? 7 : laptopScreen ? 9 : 10,
//                           fontSize: mobileScreen ? 12 : laptopScreen ? 10 : 13,
//                           background: "#fff",
//                           borderRadius: 0,
//                         },
//                       }}
//                       variant="outlined"
//                       sx={{
//                         margin: 0,
//                       }}
//                     />
//                   </Grid>
//                   {/* <Grid item md={12} xs={12}>
//                     <TextField
//                       fullWidth
//                       placeholder="Enter Your Message"
//                       multiline
//                       rows={mobileScreen ? 2 : 3}
//                       value={message}
//                       inputProps={{
//                         style: {
//                           fontSize: mobileScreen ? 12 : laptopScreen ? 10 : 13,
//                           background: "#fff",
//                           borderRadius: 0,
//                         },
//                       }}
//                       variant="outlined"
//                       sx={{
//                         margin: 0,
//                       }}
//                     />
//                   </Grid> */}
//                   <Grid item md={12} xs={12}>
//                     <Button
//                       type="submit"
//                       variant="contained"
//                       color="primary"
//                       fullWidth
//                       onClick={handleSubmit} // Go back to the previous page
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         height: mobileScreen ? 30 : laptopScreen ? 35 : 40,
//                         fontWeight: 600,
//                         borderRadius: 0,
//                         background: "#bd0745",
//                         "&:hover": {
//                           background: "#bd0745",
//                         },
//                         fontSize: mobileScreen ? 14 : laptopScreen ? 14 : 15,
//                       }}
//                     >
//                       Apply Now
//                     </Button>
//                   </Grid>
//                 </Grid>

//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Modal>
//   );
// };

// export default Popup;

import {
  Button,
  Divider,
  Grid,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import admission from "../../../../assets/popup.png";

const Popup = ({ open, onClose, isApplyButton }) => {
  const mobileScreen = useMediaQuery(
    "(min-width: 360px) and (max-width: 599px)"
  );
  const laptopScreen = useMediaQuery(
    "(min-width: 1200px) and (max-width: 1440px)"
  );

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateName = (value) => {
    if (!/^[A-Za-z\s]+$/.test(value) || value.length < 3) {
      setNameError("Enter a valid name");
      return false;
    }
    setNameError("");
    return true;
  };

  const validatePhone = (value) => {
    if (!/^\d{10}$/.test(value)) {
      setPhoneError("Enter a valid 10-digit phone number");
      return false;
    }
    setPhoneError("");
    return true;
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    validateName(value);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setPhone(value);
    validatePhone(value);
  };

  const handleSubmit = async () => {
    if (!validateName(name) || !validatePhone(phone)) return;

    const requestData = {
      name,
      phone,
      leadType: "PodarPrepYelahanka",
      leadFlag: "Paid",
    };

    try {
      const response = await axios.post(
        "https://gmc.getmycollege.com/university/addExternalSourceLead",
        requestData,
        { headers: { "Content-Type": "application/json" } }
      );

      if (response?.status >= 200 && response?.status < 300) {
        setName("");
        setPhone("");
        localStorage.setItem("isFormSubmited", true);
        onClose();
        alert("Thank You!");
      } else {
        alert("Failed to add lead");
      }
    } catch (error) {
      alert("Something went wrong. Please try again later.");
      console.error(error);
    }
  };

  return (
    <Modal open={open} onClose={isApplyButton && onClose}>
      <Grid
        container
    
        xs={12}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          background: "#ffffff",
          borderRadius: 1,
          width: { xs: "350px", md: "400px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: laptopScreen ? 2 : 4,
          py: mobileScreen ? 2 : laptopScreen ? 4 : 0,
        }}
      >
        {/* <Grid
            item
            md={6}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: mobileScreen ? "center" : "center",
            }}
          >
            <Grid
              sx={{
                width: mobileScreen ? 330 : laptopScreen ? 870 : 800,
                height: mobileScreen ? 240 : laptopScreen ? 450 : 560,
              }}
            >
              <img
                src={admission}
                alt="admissionbanner"
                style={{ width: "300px", height: "300px", objectFit: "fill" }}
              />
            </Grid>
          </Grid> */}

        <img
          src={admission}
          alt="admissionbanner"
          style={{ width: "120px", height: "120px", objectFit: "fill" }}
        />
        <Typography
          variant="h3"
          sx={{
            fontSize: mobileScreen ? 20 : laptopScreen ? 25 : 28,
            fontWeight: 600,
          }}
        >
          Admission Enquiry
        </Typography>

        <Divider sx={{ background: "#2E65AD", width: 150, my: 2 }} />

        <Typography
          sx={{
            fontSize: laptopScreen ? 15 : 17,
            textAlign: "center",
            px: mobileScreen ? 3 : 0,
          }}
        >
          Join the Podar Prep Family - Where Learning Feels Like Fun!
        </Typography>

        {/* <Divider sx={{ background: "#2E65AD", width: 150, my: 2 }} /> */}

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: mobileScreen ? 2 : laptopScreen ? 3 : 4,
          }}
        >
          <Grid container spacing={mobileScreen ? 1.5 : 3}>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                placeholder="Enter Your Name"
                value={name}
                onChange={handleNameChange}
                error={!!nameError}
                helperText={nameError}
                inputProps={{
                  style: {
                    fontSize: mobileScreen ? 12 : laptopScreen ? 10 : 13,
                    background: "#ffff",
                  },
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                placeholder="Enter Your Ph.No"
                value={phone}
                onChange={handlePhoneChange}
                error={!!phoneError}
                helperText={phoneError}
                inputProps={{
                  maxLength: 10,
                  style: {
                    fontSize: mobileScreen ? 12 : laptopScreen ? 10 : 13,
                    background: "#fff",
                  },
                }}
                // variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  height: mobileScreen ? 30 : laptopScreen ? 40 : 40,
                  fontWeight: 600,
                  borderRadius: 0,
                  background: "#1d67ab",
                  "&:hover": { background: "#fdce0d" },
                  fontSize: mobileScreen ? 14 : laptopScreen ? 14 : 15,
                }}
              >
                Apply Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Popup;

