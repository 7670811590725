import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import location from "../../../../assets/location.png";
import phone from "../../../../assets/phone.png";
import clock from "../../../../assets/clock.png";
import contact from "../../../../assets/contact.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    class: "",
    description: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    number: "",
    class: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const tempErrors = { name: "", number: "", class: "" };
    let isValid = true;

    if (!formData.name.trim()) {
      tempErrors.name = "Name is required.";
      isValid = false;
    }

    if (!formData.number.trim()) {
      tempErrors.number = "number is required.";
      isValid = false;
    }

    if (!formData.class.trim()) {
      tempErrors.class = "class is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Form submitted successfully with data: ", formData);
    } else {
      console.log("Form submission failed. Errors: ", errors);
    }
  };

  return (
    <>
      <Grid
        container
        id="contact"
        padding="30px"
        justifyContent="center"
        sx={{ width: "100%" }}
      >
        {/* Cards Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Box
            sx={{
              gap: 5,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                width: "300px",
                height: "250px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                },
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CardContent>
                <img
                  src={location}
                  alt="location"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                  Our Location
                </Typography>
                <Typography>
                  Next to KK Kids Hospital, Opp. to MEC School, Yelahanka New
                  Town, Bangalore - 64
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                width: "300px",
                height: "250px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                },
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CardContent>
                <img
                  src={phone}
                  alt="phone"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                  CALL US
                </Typography>
                <Typography>ppyelahanka@podarprep.com</Typography>
                <Typography>+91 9844565757</Typography>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              mt: 4,
              ml: { xs: 0, md: 20 },
            }}
          >
            <Card
              sx={{
                width: "300px",
                height: "250px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.1)",
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                },
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CardContent>
                <img
                  src={clock}
                  alt="clock"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
                <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
                  Working Hours
                </Typography>
                <Typography>Mon-Fri: 9 am to 5 pm</Typography>
                <Typography>Sat: 9 am to 3 pm</Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>

        {/* Form Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{ px: { xs: 0, md: 10 }, mt: { xs: 4, md: 0 } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              justifyContent: "flex-start",
              boxShadow: 4,
              px: 5,
              py: 2,
              // Align form elements centrally
            }}
          >
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "bold",
                textAlign: "center",
                color: "#01234c",
              }}
            >
            Admission Enquiry
            </Typography>

            <img
              src={contact}
              alt="GMC Logo"
              style={{ width: "150px", height: "150px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <Typography sx={{ mb: 1 }}>Name</Typography>
              <TextField
                variant="outlined"
                name="name"
                placeholder="Enter Your Name"
                value={formData.name}
                onChange={handleInputChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    borderRadius: "5px",
                    "&:hover fieldset": {
                      borderColor: "#1976d2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                      boxShadow: "0 0 5px 2px rgba(25, 118, 210, 0.4)",
                    },
                  },
                }}
              />
            </Box>

            {/* number Field */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <Typography sx={{ mb: 1 }}>Ph.No</Typography>
              <TextField
                variant="outlined"
                name="number"
                placeholder="Enter Your Number"
                value={formData.number}
                onChange={handleInputChange}
                error={Boolean(errors.number)}
                helperText={errors.number}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    borderRadius: "5px",
                    "&:hover fieldset": {
                      borderColor: "#1976d2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                      boxShadow: "0 0 5px 2px rgba(25, 118, 210, 0.4)",
                    },
                  },
                }}
              />
            </Box>

            {/* class Field */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <Typography sx={{ mb: 1 }}>Class</Typography>
              <TextField
                variant="outlined"
                name="class"
                placeholder="Enter Class"
                value={formData.class}
                onChange={handleInputChange}
                error={Boolean(errors.class)}
                helperText={errors.class}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "40px",
                    borderRadius: "5px",
                    "&:hover fieldset": {
                      borderColor: "#1976d2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                      boxShadow: "0 0 5px 2px rgba(25, 118, 210, 0.4)",
                    },
                  },
                }}
              />
            </Box>

            {/* Submit Button */}
            <Button
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#1d67ab",
                color: "#fff",
                mt: 2,
                borderRadius: "20px",
                width: "100px",
                height: "40px",
                textAlign: "center",
                "&:hover": {
                  backgroundColor: "#1d67ab",
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
