import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import earth from "../../../../assets/earth.png";
import playground from "../../../../assets/playground.png";
import rooms from "../../../../assets/rooms.png";

const InfoPage = () => {
  return (
    <>
      <Grid
        container
        id="about"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: { xs: 5, md: 10 },
          gap: 2,
        }}
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: { xs: "25px", md: "30px" } }}
        >
          About our School
        </Typography>
        <Typography
          // Adjusted for better responsive spacing
          sx={{
            fontSize: "16px",
            lineHeight: "1.6",
            ml: { xs: 0, md: 10 },
            textAlign: { xs: "justify", md: "center" },
          }}
        >
          Join the fun-filled journey of learning and laughter at Yelahanka's
          biggest Podar Prep preschool, where every day is an exciting new
          chapter for your little one.
        </Typography>
        {[
          {
            title: "Clean Environment",
            description:
              "State-of-the-Art Facilities - Our preschool boasts modern amenities designed to inspire and engage young learners.",
            image: earth,
          },
          {
            title: "BIG PLAYGROUND & AREA",
            description:
              "Expansive Playgrounds - Where laughter echoes and friendships blossom, our vast outdoor spaces offer endless opportunities for active play and exploration.",
            image: playground,
          },
          {
            title: "ARRANGE ROOMS",
            description:
              "Thoughtfully Designed Classrooms: From cozy reading corners to dynamic play zones, our carefully arranged rooms provide stimulating environments for every aspect of your child's development.",
            image: rooms,
          },
        ].map((item, index) => (
          <Grid
            item
            key={index}
            from="home-button"
            sx={{
              mt: 2,
              padding: "10px",
              border: "1px solid #1d67ab",
              backgroundColor: "#1d67ab",
              display: "flex",
              flexDirection: "column", // Stack title, image, and description vertically
              width: "350px",
              height: { xs: "250px", md: "300px" },
              justifyContent: "center",
              alignItems: "center",
              boxShadow:10,
              borderRadius: "8px", // Rounded corners for aesthetic
              textAlign: "center", // Center text content
            }}
          >
            {/* Image */}
            <img
              src={item.image} // Use dynamic image source
              alt={item.title}
              style={{
                width: "100px",
                height: "100px",
                marginBottom: { xs: 5, md: 8 },
                filter: "invert(1)", // Inverts the image colors
              }}
            />

            {/* Title */}
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: { xs: "20px", md: "20px" },
                marginBottom: 2, // Add spacing below the title
              }}
            >
              {item.title}
            </Typography>

            {/* Description */}
            <Typography
              sx={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              {item.description}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        px="10px"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
      >
        <Typography
          sx={{ fontWeight: "bold", fontSize: { xs: "25px", md: "30px" } }}
        >
          PodarPrep Yelahanka
        </Typography>
        <Typography
          sx={{
            px: 4,
            py: 2,
            fontSize: "16px",
            lineHeight: "1.6",
            textAlign: { xs: "justify", md: "center" },
          }}
        >
          Witness the laughter, the learning, and the love that fill our halls,
          inviting you to be a part of something truly extraordinary. Join us on
          this enchanting adventure and see why our school is more than just a
          place—it's a home for young minds to thrive.
        </Typography>
        <Box
          sx={{
            marginTop: 3,
            width: "100%",
            maxWidth: 600,
            borderRadius: "20px",
            overflow: "hidden", // Ensures rounded corners apply to iframe
          }}
        >
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/OJX2Kb03xD0?si=kpxXRKN9Hl59mw8e"
            title="PodarPrep School YouTube Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Grid>
    </>
  );
};

export default InfoPage;
