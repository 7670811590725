import { Box, Grid, Typography, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import image2 from "../../../../assets/image.png";
import image1 from "../../../../assets/sportsday.png";

const AboutUs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      id="Pillars"
      sx={{
        padding: "10px",
        marginTop: "50px",
        textAlign: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          fontSize: { xs: "18px", md: "30px" },
          marginBottom: "2px",
        }}
      >
        Key Pillars of Our Preschool Experience
      </Typography>
      <Typography sx={{ marginBottom: "50px", mt: 1 }}>
        Pillars of Promise - Building Bright Futures Together.
      </Typography>

      {/* Tab buttons */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        sx={{ marginBottom: "20px", gap: 5 }}
      >
        <Tab
          label="Innovation"
          sx={{
            backgroundColor: "#1d67ab",
            color: "white",

            "&.Mui-selected": {
              backgroundColor: "#fccd0c",
            },
            marginRight: "20px", // Add gap between tabs
          }}
        />
        <Tab
          label="Nurturing"
          sx={{
            backgroundColor: "#1d67ab",
            color: "white",
            "&.Mui-selected": {
              backgroundColor: "#fccd0c",
            },
            marginRight: "20px", // Add gap between tabs
          }}
        />
        <Tab
          label="Inspiring"
          sx={{
            backgroundColor: "#1d67ab",
            color: "white",
            "&.Mui-selected": {
              backgroundColor: "#fccd0c",
            },
            marginRight: "20px", // Add gap between tabs
          }}
        />
        <Tab
          label="Dynamic"
          sx={{
            backgroundColor: "#1d67ab",
            color: "white",
            "&.Mui-selected": {
              backgroundColor: "#fccd0c",
            },
            marginRight: "20px", // Add gap between tabs
          }}
        />
        <Tab
          label="Engaging"
          sx={{
            backgroundColor: "#1d67ab",
            color: "white",
            "&.Mui-selected": {
              backgroundColor: "#fccd0c",
              color: "black",
            },
            marginRight: "20px", // Add gap between tabs
          }}
        />
      </Tabs>

      {/* Content based on selected tab */}
      <Grid container spacing={4} sx={{ mt: 5 }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${image2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: { xs: "250px", md: "350px" },
              maxWidth: "500px",
              margin: "0 auto",
              borderRadius: "20px",
              transition: "all 0.3s ease",
              "&:hover": {
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
              },
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            padding: { xs: 2, md: 6 },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "18px", md: "20px" },
              textAlign: { xs: "center", md: "left" },
              mt: { xs: 2, md: 0 },
            }}
          >
            WELCOME TO PodarPrep Yelahanka
          </Typography>

          <Typography
            sx={{
              marginTop: "10px",
              textAlign: "justify",
              fontSize: { xs: "14px", md: "16px" },
              ml: { xs: 1, md: 0 },
            }}
          >
            Witness the laughter, the learning, and the love that fill our
            halls, inviting you to be a part of something truly extraordinary.
            Join us on this enchanting adventure and see why our school is more
            than just a place—it's a home for young minds to thrive.
          </Typography>

          {/* Tab content */}
          <Box sx={{ marginTop: "20px", textAlign: "left" }}>
            {selectedTab === 0 && (
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "bold",
                    mt: 3,
                    mb: 1,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Innovation
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  Innovation is the cornerstone of our approach, fostering a
                  culture of creativity and forward-thinking. Through
                  cutting-edge teaching methods and curriculum design, we
                  continuously strive to push boundaries and ignite curiosity,
                  preparing our students for a rapidly evolving world.
                </Typography>
              </Box>
            )}
            {selectedTab === 1 && (
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "bold",
                    mt: 3,
                    mb: 1,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Nurturing
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  Nurturing lies at the heart of our ethos, where every child is
                  embraced with warmth and support. We cultivate a caring
                  environment that nurtures not only academic growth but also
                  emotional well-being, fostering confidence and resilience in
                  our students.
                </Typography>
              </Box>
            )}
            {selectedTab === 2 && (
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "bold",
                    mt: 3,
                    mb: 1,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Inspiring
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  Inspiring our students to reach for the stars, we cultivate an
                  environment where curiosity thrives and dreams take flight.
                  Through captivating experiences and role models, we ignite a
                  passion for learning, empowering each child to explore their
                  potential and pursue their aspirations.
                </Typography>
              </Box>
            )}
            {selectedTab === 3 && (
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "bold",
                    mt: 3,
                    mb: 1,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Dynamic
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  At our school, dynamism fuels every aspect of learning,
                  ensuring an environment that adapts and evolves with our
                  students. From interactive lessons to diverse extracurricular
                  activities, we embrace change and innovation to keep education
                  lively, relevant, and engaging.
                </Typography>
              </Box>
            )}
            {selectedTab === 4 && (
              <Box>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: "bold",
                    mt: 3,
                    mb: 1,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  Engaging
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontSize: { xs: "14px", md: "16px" },
                  }}
                >
                  In our vibrant community, engagement is more than a
                  buzzword—it's a way of life. Through interactive lessons,
                  collaborative projects, and inclusive activities, we ignite a
                  love for learning that captivates our students' minds and
                  hearts, ensuring every day is filled with excitement and
                  discovery.
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
