import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Fab } from "@mui/material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import Homepage from "./componets/pages/home/layouts/HomePage";
import Popup from "./componets/pages/home/componets/PopUp";

const App = () => {
  const [open, setOpen] = useState(false);
  const [isApplyButton, setIsApplyButton] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const isFormSubmit = localStorage.getItem("isFormSubmited");

  useEffect(() => {
    if (!isFormSubmit) {
      const handleOpen = () => {
        if (!open) {
          setOpen(true);
        }
      };
      const interval = setInterval(handleOpen, 10000);
      return () => clearInterval(interval);
    }
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScroll(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleOpenModal = () => {
    setIsApplyButton(true);
    setOpen(true);
  };
  useEffect(() => {
    if (!isFormSubmit) {
      const handleOpen = () => {
        if (!open) {
          setOpen(true);
        }
      };
      const interval = setInterval(handleOpen, 10000);
      return () => clearInterval(interval);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Homepage
              onApplyNowClick={handleOpenModal}
              isApplyButton={isApplyButton}
            />
          }
        />
      </Routes>
      <Popup open={open} onClose={handleClose} />

      {showScroll && (
        <Fab
          onClick={handleScrollToTop}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            backgroundColor: showScroll ? "#1d67ab" : "white", // Border color fills on scroll
            border: "2px solid #1d67ab",
            color: showScroll ? "white" : "black", // Icon turns white when scrolled
            transition: "all 0.3s ease-in-out", // Smooth transition effect
            "&:hover": {
              backgroundColor: "#fdce0d", // Keep border filled when hovered
              color: "black",
              border: "2px solid #fdce0d",
            },
            boxShadow: 3,
          }}
        >
          <ArrowUpwardRoundedIcon />
        </Fab>
      )}
    </BrowserRouter>
  );
};

export default App;
