import { Grid } from "@mui/material";
import React from "react";
import NewsHeader from "../componets/NewsHeader";
import Header from "../componets/Header";
import BannerPage from "../componets/BannerPage";
import Facility from "../componets/Facility";
import InfoPage from "../componets/InfoPage";
import AboutUs from "../componets/AboutUs";
import Courses from "../componets/Courses";
import Acheivements from "../componets/Acheivements";
import ExperiancedStaff from "../componets/ExperiancedStaff";
import Reviwe from "../componets/Reviwe";
import Footer from "../componets/Footer";
import Gallary from "../layouts/Gallary";
import Contact from "./Contact";

const HomePage = () => {
  return (
    <Grid>
      <NewsHeader />
      <Header />
      <BannerPage />
      {/* <Facility /> */}
      <InfoPage />
      <Courses />
      <AboutUs />
      <Acheivements />
      {/* <ExperiancedStaff /> */}
      {/* <Reviwe/> */}
      <Gallary />
      <Contact />
      <Footer />
    </Grid>
  );
};

export default HomePage;
