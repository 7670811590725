import React from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import NoteAltIcon from "@mui/icons-material/NoteAlt";

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      <Box
        sx={{
          backgroundColor: "#1d67ab",
          color: "#fff",
          p: isSmallScreen ? 3 : 6,
        }}
      >
        <Grid
          container
          spacing={4}
          justifyContent={isSmallScreen ? "center" : "space-between"}
        >
          {/* Links Section */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
              Links
            </Typography>
            <Divider
              sx={{
                width: "50px",
                borderWidth: "2px",
                backgroundColor: "#8c8587",
                mb: 2,
              }}
            />
            {["Home", "Courses", "Events", "Contact Us"].map((name, index) => (
              <Box
                display="flex"
                alignItems="center"
                key={index}
                sx={{ mt: 1 }}
              >
                <ArrowRightIcon sx={{ fontSize: "16px", mr: 1 }} />
                <Link to="/" style={{ color: "#fff", textDecoration: "none" }}>
                  <Typography>{name}</Typography>
                </Link>
              </Box>
            ))}
          </Grid>

          {/* About Section */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
              About
            </Typography>
            <Divider
              sx={{
                width: "50px",
                borderWidth: "2px",
                backgroundColor: "#8c8587",
                mb: 2,
              }}
            />
            {["About Us", "Gallery", "News"].map((name, index) => (
              <Box
                display="flex"
                alignItems="center"
                key={index}
                sx={{ mt: 1 }}
              >
                <ArrowRightIcon sx={{ fontSize: "16px", mr: 1 }} />
                <Link to="/" style={{ color: "#fff", textDecoration: "none" }}>
                  <Typography>{name}</Typography>
                </Link>
              </Box>
            ))}
          </Grid>

          {/* Social Media */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            textAlign={isSmallScreen ? "left" : "left"}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
              Follow Us
            </Typography>
            <Divider
              sx={{
                width: "50px",
                borderWidth: "2px",
                backgroundColor: "#8c8587",
                mb: 2,
              }}
            />
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: isSmallScreen ? "left" : "start",
                flexWrap: "wrap",
              }}
            >
              {[
                WhatsAppIcon,
                FacebookIcon,
                TwitterIcon,
                GoogleIcon,
                YouTubeIcon,
                LinkedInIcon,
                InstagramIcon,
                PinterestIcon,
              ].map((Icon, index) => (
                <IconButton
                  key={index}
                  sx={{
                    color: "#fff",
                    width: "30px",
                    height: "35px",
                    backgroundColor: "rgba(140, 133, 135, 0.2)",
                    "&:hover": { backgroundColor: "#bd0745" },
                  }}
                >
                  <Icon />
                </IconButton>
              ))}
            </Box>
          </Grid>

          {/* Feedback */}
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            textAlign={isSmallScreen ? "left" : "left"}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", mb: 2 }}>
              Feedback
            </Typography>
            <Divider
              sx={{
                width: "50px",
                borderWidth: "2px",
                backgroundColor: "#8c8587",
                mb: 2,
              }}
            />
            <Box
              display="flex"
              justifyContent={isSmallScreen ? "left" : "start"}
            >
              <NoteAltIcon sx={{ mr: 2, fontSize: "30px" }} />
              <Typography sx={{ fontSize: "18px" }}>Complain</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Bottom Bar */}
      <Box
        sx={{
          backgroundColor: "#fdce0d",
          p: 1,
          textAlign: "center",
          color: "black",
          fontWeight: "bold",
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          © 2025 ppyelahanka@podarprep.com All Rights Reserved | Developed by SN
          Info Systems.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
