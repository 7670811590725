import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";

const NewsHeader = () => {
  return (
    <>
      <Grid
        container
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          backgroundColor: "#1d67ab",
          padding: "8px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            animation: "scrollText 30s linear infinite",
          }}
        >
          <Box sx={{ display: "flex", gap: 3 }}>
            <Typography
              sx={{
                display: "inline-block",
                color: "#ffffff",
                fontSize: { xs: "12px", md: "18px" },
              }}
            >
              🎓 Admissions Open for the Academic Year 2024-25 – Enroll Now!
            </Typography>
            <Typography
              sx={{
                display: "inline-block",
                color: "#ffffff",
                fontSize: { xs: "12px", md: "18px" },
              }}
            >
              🌿 Eco Club Initiative: Join Us for the Upcoming Tree Plantation
              Drive!
            </Typography>
            <Typography
              sx={{
                display: "inline-block",
                color: "#ffffff",
                fontSize: { xs: "12px", md: "18px" },
              }}
            >
              🩺 Health Check-Up Camp for All Students
            </Typography>
          </Box>
        </Box>

        <style>
          {`
          @keyframes scrollText {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }
        `}
        </style>
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: "#fdce0d",
          color: "#ffffff",
          padding: "8px 15px",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            fontWeight: "bold",
            gap: 1,
            marginBottom: { xs: 1, md: 0 },
            flexWrap: "wrap",
            ml: { xs: 0, md: 5 },
          }}
        >
          <a href="mailto:ppyelahanka@podarprep.com">
            <MailOutlineIcon
              sx={{
                color: "#ffffff",
                fontSize: { xs: 18, md: 24, color: "black" },
              }}
            />
          </a>
          <Typography
            sx={{
              fontSize: {
                xs: "15px",
                md: "18px",
                color: "black",
                fontWeight: "bold",
              },
              fontFamily: "'Josefin Sans', sans-serif",
            }}
          >
            ppyelahanka@podarprep.com
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 14, md: 16 },
              color: "black",
            }}
          >
            Contact For Admission:
          </Typography>

          <Box sx={{ mr: 3, ml: -1 }}>
            <a href="tel:+919844565757" style={{ textDecoration: "none" }}>
              <Typography sx={{ fontWeight: "bold", color: "black" }}>
                +91 9844565757
              </Typography>
            </a>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default NewsHeader;
